import React, { useCallback, useEffect, useState } from "react";
import "./Institute.scss";
import { Button, Space, Switch, Table, Tabs } from "antd";
import CustomLayout from "../../Components/CustomLayout/CustomLayout";
import FormInputs from "../../Components/FormInputs/FormInputs";
import { errorToast, successToast } from "../../services/ToastHelper";
import { getApi, postApi } from "../../utils/apiService";
import { APPROVE_INSTITUTES, GET_ALL_INSTITUTES } from "../../utils/apiPath";
import { Oval } from "react-loader-spinner";
import debounce from "lodash/debounce";

const Institute = () => {
  const [instituteDetails, setInstituteDetails] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [isApproved, setIsApproved] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, settotalRecords] = useState(20);
  const [alignValue, setAlignValue] = useState("center");
  const [activeKey, setActiveKey] = useState("active");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  console.log(totalRecords, "totalRecords");
  const onTabChange = (key) => {
    setPageIndex(1);
    setActiveKey(key);
    if (key === "active") {
      setIsApproved(true);
    } else if (key === "inactive") {
      setIsApproved(false);
    } else {
      setIsApproved(null);
    }
  };

  const getPageSizeOptions = () => {
    if (totalRecords <= 50) {
      return ["10", "20", "50"];
    } else {
      return ["10", "20", "50", "100"];
    }
  };

  const columns = [
    {
      title: "S.Np",
      dataIndex: "s.no",
      key: "id",
      render: (_, __, index) => (pageIndex - 1) * pageSize + index + 1,
    },
    {
      title: "User Id",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "Institute Id",
      dataIndex: "instituteId",
      key: "name",
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "isApproved",
      key: "isApproved",
      render: (isApproved, record) => (
        <Button
          type={isApproved ? "primary" : "default"}
          style={{
            backgroundColor: isApproved ? "green" : "red",
            color: "#fff",
          }}
        >
          {isApproved ? "Approved" : "Unapproved"}
        </Button>
      ),
    },
    ...(!instituteDetails?.some((record) => record.isApproved)
      ? [
          {
            title: "Actions",
            key: "actions",
            render: (text, record) => (
              <Space size="middle">
                <Switch
                  checked={record.isActive}
                  onChange={(checked) =>
                    handleSwitchChange(record.userId, checked)
                  }
                />
              </Space>
            ),
          },
        ]
      : []),
  ];

  const tabItems = [
    {
      key: "active",
      label: "Approved Institutes",
      children: (
        <Table
          key={`${pageIndex}-${pageSize}`}
          dataSource={instituteDetails}
          columns={columns}
          pagination={{
            current: pageIndex, // Controlled current page
            pageSize: pageSize,
            total: totalRecords,
            showSizeChanger: true,
            // pageSizeOptions: ["10", "20", "50", "100"], // Example options
            onChange: (page, size) => {
              setPageIndex(page); // Update pageIndex
              setPageSize(size); // Update pageSize if changed
            },
          }}
        />
      ),
    },
    {
      key: "inactive",
      label: "Unapproved Institutes",
      children: (
        <Table
          dataSource={instituteDetails}
          columns={columns}
          pagination={{
            current: pageIndex,
            pageSize,
            total: totalRecords,
            showSizeChanger: true,
            pageSizeOptions: getPageSizeOptions(),
            onChange: (page, size) => {
              setPageIndex(page);
              setPageSize(size);
            },
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchallInstitutes();
  }, [pageIndex, pageSize, isApproved, debouncedQuery]);

  const fetchallInstitutes = useCallback(
    async (query) => {
      try {
        setIsLoading(true);
        const { status, data, totalRecords } = await getApi(
          GET_ALL_INSTITUTES,
          {
            params: {
              isApproved,
              name: query || searchQuery,
              pageIndex,
              pageSize,
            },
          }
        );

        if (status === 200) {
          setInstituteDetails(data.records);
          settotalRecords(data.totalRecords);
        } else if (status === 204) {
          setInstituteDetails([]);
        }
      } catch (error) {
        console.error("Error fetching institutes:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [isApproved, pageIndex, pageSize, searchQuery]
  );

  const handleSwitchChange = async (userId, isActive) => {
    const payload = {
      userId: userId,
    };

    try {
      setIsLoading(true);
      const { status, data, message } = await postApi(
        APPROVE_INSTITUTES,
        payload
      );

      if (status === 200) {
        setInstituteDetails(data);
        successToast(message);
        if (isActive) {
          onTabChange("active");
        } else {
          onTabChange("inactive");
        }
        fetchallInstitutes();
      } else {
        errorToast(message);
      }
    } catch (error) {
      console.error("Error approving institute:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      fetchallInstitutes(query);
    }, 500),
    [fetchallInstitutes]
  );

  const handleSearchChange = (e) => {
    const query = e.target?.value || "";
    setSearchQuery(query);
    debouncedSearch(query);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  return (
    <>
      <CustomLayout>
        {isLoading && (
          <div className="loader-container">
            <div className="loader">
              <Oval
                color="#86d3ff"
                height={50}
                width={50}
                radius="7"
                secondaryColor="#86d3ff"
              />
            </div>
          </div>
        )}
        <div className="institute">
          <div className="institute_container">
            <div className="institute_container_heading">
              <h3>ALL INSTITUTES</h3>
            </div>
            <div className="institute_container_buttons">
              <FormInputs
                type="text"
                value={searchQuery}
                name="search"
                placeholder="Search"
                icon="icon3"
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <Tabs
            activeKey={activeKey}
            onChange={onTabChange}
            items={tabItems}
            tabBarStyle={{ textAlign: alignValue }}
          />
        </div>
      </CustomLayout>
    </>
  );
};

export default Institute;
