import React, { useState, useEffect, useCallback } from "react";
import "./Teachers.scss";
import { Table } from "antd";
import CustomLayout from "../../Components/CustomLayout/CustomLayout";
import FormInputs from "../../Components/FormInputs/FormInputs";
import { getApi } from "../../utils/apiService";
import { GET_ALL_TEACHERS } from "../../utils/apiPath";
import { Oval } from "react-loader-spinner";
import debounce from "lodash/debounce";

const Teachers = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [instituteDetails, setInstituteDetails] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [isApproved, setIsApproved] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalRecords, settotalRecords] = useState(0);
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  console.log(instituteDetails,"instituteDetails")
  // Dummy data
  const dummyData = [
    {
      userId: "1",
      instituteId: "101",
      userName: "John Doe",
      email: "john.doe@example.com",
    },
    {
      userId: "2",
      instituteId: "102",
      userName: "Jane Smith",
      email: "jane.smith@example.com",
    },
    {
      userId: "3",
      instituteId: "103",
      userName: "Alice Johnson",
      email: "alice.johnson@example.com",
    },
    {
      userId: "4",
      instituteId: "104",
      userName: "Bob Brown",
      email: "bob.brown@example.com",
    },
  ];

  const columns = [
    {
      title: "S.Np",
      dataIndex: "s.no",
      key: "id",
      render: (_, __, index) => (pageIndex - 1) * pageSize + index + 1,
    },
    {
      title: "TeacherId",
      dataIndex: "teacherId",
      key: "teacherId",
    },
    {
      title: "TeacherName",
      dataIndex: "teacherName",
      key: "teacherName",
    },
    {
      title: "Institute",
      dataIndex: "institute",
      key: "institute",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
  ];

  useEffect(() => {
    fetchallTeachers();
  }, [pageIndex, pageSize, debouncedQuery]);

  const handleSearch = useCallback((name, value) => {
    setSearchQuery(value);
  }, []);

  const fetchallTeachers = async () => {
    setIsLoading(true);

    const params = {
      name: searchQuery,
      pageIndex,
      pageSize,
    };

    try {
      const { status, data } = await getApi(GET_ALL_TEACHERS, { params });

      if (status === 200 ) {
        setIsLoading(false);
        setInstituteDetails(data?.records);
        settotalRecords(data?.totalRecords);
      } else if (status === 204 || data.length === 0) {
        // setInstituteDetails(dummyData);
        // settotalRecords(dummyData.length);
      }
    } catch (error) {
      console.error("Error fetching teachers:", error);
      // setInstituteDetails(dummyData);
      // settotalRecords(dummyData.length);
    } finally {
      setIsLoading(false);
    }
  };

  // Debounce search
  const debouncedSearch = useCallback(
    debounce((query) => {
      fetchallTeachers(query);
    }, 500),
    [fetchallTeachers]
  );

  const handleSearchChange = (e) => {
    const query = e.target?.value || "";
    setSearchQuery(query);
    debouncedSearch(query);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  return (
    <CustomLayout>
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}
      <div className="institute">
        <div className="institute_container">
          <div className="institute_container_heading">
            <h3>ALL TEACHERS</h3>
          </div>
          <div className="institute_container_buttons">
            <FormInputs
              type="text"
              value={searchQuery}
              name="search"
              placeholder="Search"
              icon="icon3"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={instituteDetails}
          pagination={{
            current: pageIndex,
            pageSize,
            total: totalRecords,
            onChange: (page, pageSize) => {
              setPageIndex(page);
              setPageSize(pageSize);
            },
            pageSizeOptions: ["10", "20", "50", "100"],
          }}
        />
      </div>
    </CustomLayout>
  );
};

export default Teachers;
