import React, { useState } from "react";
import "../Login/Login.scss";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import FormInputs from "../../Components/FormInputs/FormInputs";
import Buttons from "../../Components/Buttons/Buttons";
import { ADMIN_RESETPASSWORD } from "../../utils/apiPath";
import { postApi } from "../../utils/apiService";
import { errorToast, successToast } from "../../services/ToastHelper";
import OtpInput from "react-otp-input";
import mainimage from "../../Assets/main.png";

const initialValues = {
  otp: "",
  newPassword: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem("email"); 
  const [login, setLogin] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (nameOrEvent, value) => {
    if (typeof nameOrEvent === "string") {
      // Called with a name and value (e.g., from OtpInput)
      setLogin({
        ...login,
        [nameOrEvent]: value,
      });
      setErrors({
        ...errors,
        [nameOrEvent]: "",
      });
    } else {
      // Called with an event object (e.g., from FormInputs)
      const { name, value } = nameOrEvent?.target;
      setLogin({
        ...login,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.otp) {
      errObj.otp = "This field is required";
    } else if (login.otp.length !== 4) {
      errObj.otp = "OTP must be 4 digits";
    } else {
      errObj.otp = "";
    }

    if (!login.newPassword) {
      errObj.newPassword = "This field is required";
    } else if (login.newPassword.length < 8) {
      errObj.newPassword = "Password must be at least 8 characters";
    } else {
      errObj.newPassword = "";
    }

    if (!login.confirmPassword) {
      errObj.confirmPassword = "This field is required";
    } else if (login.newPassword !== login.confirmPassword) {
      errObj.confirmPassword = "Passwords do not match";
    } else {
      errObj.confirmPassword = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    return Object.values(errObj).every((x) => x === "");
  };

  const handleResetPassword = async () => {
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        email,
        otp: login.otp,
        newPassword: login.newPassword,
      };
      const { status, message } = await postApi(ADMIN_RESETPASSWORD, payload);
      if (status === 200) {
        setIsLoading(false);
        navigate("/");
        successToast(message);
      } else {
        errorToast(message);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="login">
      {isLoading && (
        <div className="loader-container">
          <div className="loader">
            <Oval
              color="#86d3ff"
              height={50}
              width={50}
              radius="7"
              secondaryColor="#86d3ff"
            />
          </div>
        </div>
      )}
      <div className="login_cont">
        <div className="heading-image">
          <img src={mainimage} alt="" />
        </div>
        <div className="login_cont_section">
          <div className="login_cont_section_header">
            <h3>Create New Password</h3>
          </div>
          <div className="login_cont_section_inputs">
            <div className="login_cont_section_inputs_otp">
              <label className="input-label">Enter OTP</label>
              <OtpInput
                value={login.otp}
                onChange={(otp) => handleChange("otp", otp)}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
              {errors.otp && <span className="error">{errors.otp}</span>}
            </div>
            <div className="login_cont_section_inputs_data">
              <FormInputs
                title={"New Password"}
                type={"password"}
                placeholder={"********"}
                name="newPassword"
                icon="icon2"
                value={login.newPassword}
                onChange={handleChange}
              />
              {errors.newPassword && (
                <span className="error">{errors.newPassword}</span>
              )}
            </div>
            <div className="login_cont_section_inputs_data">
              <FormInputs
                title={"Confirm Password"}
                type={"password"}
                placeholder={"********"}
                name="confirmPassword"
                icon="icon2"
                value={login.confirmPassword}
                onChange={handleChange}
              />
              {errors.confirmPassword && (
                <span className="error">{errors.confirmPassword}</span>
              )}
            </div>
          </div>
          <div className="login_cont_section_buttons">
            <Buttons variant="primary" onClick={handleResetPassword}>
              Change Password
            </Buttons>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
