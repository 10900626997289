// Admin Auth
export const ADMIN_LOGIN_DETAILS = "admin/login";
export const ADMIN_FORGOTPASSWORD = "admin/forgotpassword";
export const ADMIN_RESETPASSWORD = "admin/resetpassword";
export const GET_ALL_INSTITUTES = "admin/getinstitutes";
export const GET_ALL_TEACHERS = "admin/getteachers";
export const GET_ALL_STUDENTS = "admin/getstudents";
export const APPROVE_INSTITUTES = "admin/approveinstitute";

















