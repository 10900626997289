import React, { useState } from "react";
import "./Login.scss";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import mainimage from "../../Assets/main.png";
// import { errorToast, successToast } from "../../../services/ToastHelper";

import FormInputs from "../../Components/FormInputs/FormInputs";
import Buttons from "../../Components/Buttons/Buttons";
import { ADMIN_LOGIN_DETAILS } from "../../utils/apiPath";
import { postApi } from "../../utils/apiService";
import { errorToast, successToast } from "../../services/ToastHelper";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin({
      ...login,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateFields = () => {
    let errObj = { ...initialValues };

    if (!login.email) {
      errObj.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(login.email)) {
      errObj.email = "Enter valid email-id";
    } else {
      errObj.email = "";
    }

    if (!login.password) {
      errObj.password = "This field is required";
    } else if (login.password.length < 8) {
      errObj.password = "Password must be at least 8 characters";
    } else {
      errObj.password = "";
    }

    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleLogin = async () => {
    if (validateFields()) {
      setIsLoading(true);
      const payload = {
        email: login.email,
        password: login.password,
      };
      const res = await postApi(ADMIN_LOGIN_DETAILS, payload);
      const { status, data, message } = res;
      if (status === 200) {
        localStorage.setItem("accessToken", data);
        setIsLoading(false);
        successToast(message);
        navigate("/institutes");
      } else {
        setIsLoading(false);
        errorToast(message);
      }
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgotPassword");
  };

  return (
    <>
      <div className="login">
        {isLoading && (
          <div className="loader-container">
            <div className="loader">
              <Oval
                color="#86d3ff"
                height={50}
                width={50}
                radius="7"
                secondaryColor="#86d3ff"
              />
            </div>
          </div>
        )}
        <div className="login_cont">
          <div className="heading-image">
            <img src={mainimage} alt="" />
          </div>
          <div className="login_cont_section">
            <div className="login_cont_section_header">
              <h3>Log In</h3>
            </div>
            <div className="login_cont_section_inputs">
              <div className="login_cont_section_inputs_data">
                <FormInputs
                  title={"Enter Email"}
                  type={"text"}
                  placeholder={"Enter Your Email"}
                  name="email"
                  icon="icon1"
                  value={login.email}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <div className="login_cont_section_inputs_data">
                <FormInputs
                  title={"Password"}
                  type={"password"}
                  placeholder={"********"}
                  name="password"
                  icon="icon2"
                  value={login.password}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleLogin();
                    }
                  }}
                />
                {errors.password && (
                  <span className="error">{errors.password}</span>
                )}
              </div>
            </div>
            <div className="login_cont_section_forgot">
              <p onClick={handleForgotPassword}>Forgot Password?</p>
            </div>
            <div className="login_cont_section_buttons">
              <Buttons variant="primary" onClick={handleLogin}>
                Login
              </Buttons>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
