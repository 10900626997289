import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/Login/Login";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Dashboard from "../pages/Dashboard/Dashboard";
import Institute from "../pages/Institutes/Institute";
import Teachers from "../pages/Teachers/Teachers";
import Students from "../pages/Students/Students";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import ProtectedRoute from "./ProtectedRoute"; // Import your ProtectedRoute component

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />

        {/* Protected Routes */}
        <Route 
          path="/institutes" 
          element={
            <ProtectedRoute>
              <Institute />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/teachers" 
          element={
            <ProtectedRoute>
              <Teachers />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/students" 
          element={
            <ProtectedRoute>
              <Students />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
